export default function GTag() {
    const script_content = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-5T5Q1J66EQ', {
            cookie_flags: 'secure;samesite=none'
        });
    `;
    return (
        <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-5T5Q1J66EQ"></script>
            <script dangerouslySetInnerHTML={{ __html: script_content }}></script>
        </>
    );
}